import React, { useState, useEffect } from 'react';
import { PanelProps } from '@grafana/data';
import { css } from '@emotion/css';
import { useTheme2, Slider } from '@grafana/ui';
import { locationService } from '@grafana/runtime';

interface TimestampSliderOptions {
  variableName: string;
}

interface Props extends PanelProps<TimestampSliderOptions> {}

export const TimestampSliderPanel: React.FC<Props> = ({ options, data, width, height, replaceVariables }) => {
  const theme = useTheme2();
  const styles = getStyles(theme);

  const [timestamps, setTimestamps] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const extractedTimestamps = data.series
      .flatMap(series => series.fields.find(field => field.type === 'time')?.values.toArray() || [])
      .map(timestamp => new Date(timestamp).toISOString())
      .sort();
    
    console.log('Extracted timestamps:', extractedTimestamps);
    setTimestamps(extractedTimestamps);
  }, [data]);

  const updateVariable = (variableName: string, newValue: string) => {
    console.log(`Updating variable ${variableName} to:`, newValue);
    locationService.partial({ [`var-${variableName}`]: newValue }, true);
  };

  const handleSliderChange = (value: number) => {
    setSelectedIndex(value);
    const newTimestamp = timestamps[value];
    console.log(`Updating ${options.variableName} to:`, newTimestamp);
    updateVariable(options.variableName, newTimestamp);
  };

  const formatTimestamp = (timestamp: string) => {
    return new Date(timestamp).toLocaleString();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.sliderContainer}>
        <div className={styles.selectedTimestamp}>
          {timestamps[selectedIndex] && formatTimestamp(timestamps[selectedIndex])}
        </div>
        <Slider
          value={selectedIndex}
          min={0}
          max={timestamps.length - 1}
          onChange={handleSliderChange}
        />
        <div className={styles.timestampRange}>
          <span>{timestamps[0] && formatTimestamp(timestamps[0])}</span>
          <span>{timestamps[timestamps.length - 1] && formatTimestamp(timestamps[timestamps.length - 1])}</span>
        </div>
      </div>
    </div>
  );
};

const getStyles = (theme: any) => {
  return {
    wrapper: css`
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    `,
    sliderContainer: css`
      width: 90%;
    `,
    selectedTimestamp: css`
      text-align: center;
      margin-bottom: 10px;
    `,
    timestampRange: css`
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      font-size: 0.8em;
    `,
  };
};
