import React, { useState, useEffect, useCallback } from 'react';
import { PanelProps, TimeRange } from '@grafana/data';
import { css } from '@emotion/css';
import { useTheme2, Slider } from '@grafana/ui';
import { locationService } from '@grafana/runtime';
import { debounce } from 'lodash';

interface TimestampSliderOptions {
  variableName: string;
}

interface Props extends PanelProps<TimestampSliderOptions> {}

export const TimestampSliderPanel: React.FC<Props> = ({ options, data, width, height, replaceVariables, timeRange }) => {
  const theme = useTheme2();
  const styles = getStyles(theme);

  const [timestamps, setTimestamps] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [key, setKey] = useState<number>(0); // Neuer State für Forced Rerendering
  
  useEffect(() => {
	// get timestamps from query
    const extractedTimestamps = data.series
      .flatMap(series => series.fields.find(field => field.type === 'time')?.values.toArray() || [])
      .map(timestamp => new Date(timestamp).toISOString())
      .sort();
    
    if (extractedTimestamps.length === 0) {
      return;
    }

    // get current selected timestamp
    const currentSelectedTimestamp = timestamps[selectedIndex];
    
    setTimestamps(extractedTimestamps);

    if (!currentSelectedTimestamp) {
      // if no timestamp is selected, choose the newest
      setSliderValue(0); // Slider ganz rechts
      setSelectedIndex(extractedTimestamps.length - 1); // Letzter (neuester) Timestamp
      updateVariable(options.variableName, extractedTimestamps[extractedTimestamps.length - 1]);
      return;
    }

    const currentDate = new Date(currentSelectedTimestamp).getTime();
    const newTimestampsAsNumbers = extractedTimestamps.map(t => new Date(t).getTime());
    
    // check if selected timestamp is in the selected time Range
    if (currentDate >= newTimestampsAsNumbers[0] && currentDate <= newTimestampsAsNumbers[newTimestampsAsNumbers.length - 1]) {
      // find nearest timestamp
      let closestIndex = 0;
      let closestDiff = Math.abs(newTimestampsAsNumbers[0] - currentDate);
      
      for (let i = 1; i < newTimestampsAsNumbers.length; i++) {
        const diff = Math.abs(newTimestampsAsNumbers[i] - currentDate);
        if (diff < closestDiff) {
          closestDiff = diff;
          closestIndex = i;
        }
      }
      
      setSelectedIndex(closestIndex);
      // Invert slider value for visualization purpose
      const newSliderValue = extractedTimestamps.length - 1 - closestIndex;
      setSliderValue(newSliderValue);
      updateVariable(options.variableName, extractedTimestamps[closestIndex]);
              // Force rerender
      setKey(prev => prev + 1);
    } else {
      // if timestamp out of range set to newest time stamp
      setSliderValue(0); // Slider ganz rechts
      setSelectedIndex(extractedTimestamps.length - 1); // Letzter (neuester) Timestamp
      updateVariable(options.variableName, extractedTimestamps[extractedTimestamps.length - 1]);
              // Force rerender
      setKey(prev => prev + 1);    
}

  }, [timeRange, options.variableName]);

  const updateVariable = useCallback((variableName: string, newValue: string) => {
    console.log(`Updating variable ${variableName} to:`, newValue);
    locationService.partial({ [`var-${variableName}`]: newValue }, true);
  }, []);

  const debouncedUpdate = useCallback(
    debounce((slider_index: number) => {
    // invert timestamp index
      const timestampIndex = timestamps.length - 1 - slider_index;      
      setSelectedIndex(timestampIndex);
      const newTimestamp = timestamps[timestampIndex];
      //console.log('Updating selectedTimestamp to:', newTimestamp);
      updateVariable(options.variableName, newTimestamp);
    }, 300),
    [timestamps, updateVariable]
  );

  const handleSliderChange = (value: number) => {
    setSliderValue(value);
    debouncedUpdate(value);
  };

  const handleSliderChangeCommitted = (value: number) => {
    const timestampIndex = timetamps.length-1-value
    setSelectedIndex(timestampIndex);   
    const newTimestamp = timestamps[timestampIndex];
    //console.log('Slider released. Updating selectedTimestamp to:', newTimestamp);
    updateVariable(options.variableName, newTimestamp);
  };

  const formatTimestamp = (timestamp: string) => {
    return new Date(timestamp).toLocaleString();
  };

  return (
    <div key={key} className={styles.wrapper}>
      <div className={styles.sliderContainer}>
        <div className={styles.selectedTimestamp}>
          {timestamps[selectedIndex] && formatTimestamp(timestamps[selectedIndex])}
        </div>
        <Slider
          value={sliderValue}
          min={0}
          max={timestamps.length - 1}
          onChange={handleSliderChange}
          onChangeCommitted={handleSliderChangeCommitted}
          reverse={true}
        />
        <div className={styles.timestampRange}>
          <span>{timestamps[0] && formatTimestamp(timestamps[0])}</span>
          <span>{timestamps[timestamps.length - 1] && formatTimestamp(timestamps[timestamps.length - 1])}</span>
        </div>
      </div>
    </div>
  );
};

const getStyles = (theme: any) => {
  return {
    wrapper: css`
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    `,
    sliderContainer: css`
      width: 90%;
    `,
    selectedTimestamp: css`
      text-align: center;
      margin-bottom: 10px;
    `,
    timestampRange: css`
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      font-size: 0.8em;
    `,
  };
};
