import React from 'react';
import { PanelEditorProps } from '@grafana/data';
import { Input } from '@grafana/ui';

interface TimestampSliderOptions {
  variableName: string;
}

export const TimestampSliderEditor: React.FC<PanelEditorProps<TimestampSliderOptions>> = ({ options, onOptionsChange }) => {
  return (
    <div className="gf-form">
      <label className="gf-form-label width-10">Variable Name</label>
      <Input
        className="gf-form-input width-10"
        value={options.variableName}
        onChange={(e) => onOptionsChange({ ...options, variableName: e.currentTarget.value })}
        placeholder="Enter variable name"
      />
    </div>
  );
};
