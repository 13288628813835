import { PanelPlugin } from '@grafana/data';
import { TimestampSliderPanel } from './TimestampSliderPanel';
import { TimestampSliderEditor } from './TimestampSliderEditor';

export const plugin = new PanelPlugin<TimestampSliderOptions>(TimestampSliderPanel)
  .setEditor(TimestampSliderEditor)
  .setPanelOptions((builder) => {
    return builder
      .addTextInput({
        path: 'variableName',
        name: 'Variable Name',
        description: 'Name of the Grafana variable to update',
        defaultValue: 'selectedTimestamp',
      });
  });
